<template>
    <div class="loader" id="propertiesLoader">
        <ProgressSpinner style="width:50px;height:50px" />
    </div>
</template>
<script>
import service from "@/service/auth-service";
import aws_exports from '../aws-exports';
import cm from 'maksof-common'
export default {
    mounted() {
        this.login()
    },
    methods: {
        async login() {
            let url = `https://cognito-idp.${aws_exports.aws_project_region}.amazonaws.com/`;
            if(!cm.isEmpty(this.$route.params.token)) {
                let body = { AccessToken: this.$route.params.token };
                let responseAws = await service.apiExecute('post',url,body);
                if (responseAws.status == 200){
                    var aT = JSON.parse(responseAws.config.data);
                    localStorage.setItem('userAccessToken', aT['AccessToken']);
                    if(responseAws.data.Username == 'admin') this.$router.push('/admin/add-component')
                    else this.$router.push('/all-projects')
                }else{
                    localStorage.clear();
                    this.$router.push('/login')
                    //ALERT SESSION EXPIRED
                }
            } else {
                this.$router.push('/login')
                localStorage.clear();
                //ALERT SESSION EXPIRED
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.loader{
    position: absolute;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
</style>