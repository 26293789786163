'<template lang="">
    <div class="side-nav"  v-bind:class="{ active: nav == true }">
      <div class="logo-div">
          <a>Telli Pipeline</a>
      </div>
      <div class="menu-div">
          <ul class="menu">
              <li class="menu-item collapsed" data-toggle="collapse" data-target="#list1" :class="{collapsed: $route.name != 'VIEW COMPONENTS' && $route.name != 'CREATE COMPONENT'}" >
                  <a class="menu-link"><div class="menu-name"><span class="menu-icon"><i class="fas fa-project-diagram"></i></span>components</div><span class="icon-caret"><i class="fas fa-caret-down"></i></span></a>
                    <ul class="sub-menu collapse" id="list1" :class="{show: $route.name == 'VIEW COMPONENTS' || $route.name == 'CREATE COMPONENT'}">
                        <li class="sub-menu-item active"><router-link to="/admin/add-component"><i class="fas fa-circle mr-2"></i>create component</router-link></li>
                        <li class="sub-menu-item active"><router-link to="/admin/components-list"><i class="fas fa-circle mr-2"></i>View Component</router-link></li>
                        <!-- <li class="sub-menu-item"><a>demo1</a></li>
                        <li class="sub-menu-item"><a>demo1</a></li> -->
                    </ul>
              </li>
             <!-- <li class="menu-item" data-toggle="collapse" data-target="#list2"><a>demo2</a>
                    <ul class="sub-menu collapse" id=list2>
                        <li class="sub-menu-item"><a>demo2</a></li>
                        <li class="sub-menu-item"><a>demo2</a></li>
                        <li class="sub-menu-item"><a>demo2</a></li>
                        <li class="sub-menu-item"><a>demo2</a></li>
                    </ul>
              </li>
              <li class="menu-item" data-toggle="collapse" data-target="#list3"><a>demo3</a>
                    <ul class="sub-menu collapse" id="list3">
                        <li class="sub-menu-item"><a>demo3</a></li>
                        <li class="sub-menu-item"><a>demo3</a></li>
                        <li class="sub-menu-item"><a>demo3</a></li>
                        <li class="sub-menu-item"><a>demo3</a></li>
                    </ul>
              </li>
              <li class="menu-item" data-toggle="collapse" data-target="#list4"><a>demo4</a>
                    <ul class="sub-menu collapse" id="list4">
                        <li class="sub-menu-item"><a>demo4</a></li>
                        <li class="sub-menu-item"><a>demo4</a></li>
                        <li class="sub-menu-item"><a>demo4</a></li>
                        <li class="sub-menu-item"><a>demo4</a></li>
                    </ul>
              </li> -->
          </ul>
      </div>
    </div>
</template>
<script>
export default {
    props:['nav'],

}
</script>
<style lang="scss" scope>
    @import 'sidenav.scss';
</style>'