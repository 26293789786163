<template>
  <nav>
    <ul>
      <li>
        <a class="logo">
          <img src="../assets/img/logo-white.png" />
        </a>
      </li>
      <li>
        <a href="">
          <span>
            <i class="fas fa-house-damage"></i>
          </span>
          <span>Home</span>
        </a>
      </li>
      <li>
        <router-link to="/all-projects">
            <span><i class="fas fa-tasks"></i></span>
            <!-- <span class="fas fa-project-diagram"></span> -->
            <span>Project</span>
        </router-link>  
      </li>
    </ul>
    <ul class="bottom">
      <li>
        <a href="">
          <span>
            <i class="fas fa-question"></i>
          </span>
        </a>
      </li>
      <li class="user-box">
        <a>
          <span>
            <i class="fas fa-user"></i>
          </span>
        </a>
        <div class="hover-box">
          <div class="heading"><h1>My Account</h1></div>
          <ul>
            <li><a>Workspace</a></li>
            <li><a>Profile</a></li>
            <li><a>Billing</a></li>
            <li><a>Local Agreement</a></li>
            <li @click="logout"><a>Logout</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            unsubscribeAuth : undefined,
        }   
    },
    methods:{
        ...mapActions({
            globalLogoutVue            : "auth/globalLogout",
        }),
        logout : async function () {
            await this.globalLogoutVue();
            localStorage.clear();
            this.$router.push('/login');
        }
    }
}
</script>
<style lang="scss">
@import "../assets/css/template.scss";
nav {
  background: $primary-color;
  min-height: 100vh;
  @include column;
  @include justify-space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .logo{
    display: block;
    width: 60px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        color: #ffffff;
        padding: 15px 10px;
        @include column;
        @include align-center;
        @include justify-center;
        cursor: pointer;
        font-family: $primary-font;
        font-size: 10px;
        font-weight: 500;
        &:hover {
            background: #1f93c1;
            text-decoration: none;
          }
        span {
          font-family: $primary-font;
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          &:first-child {
            font-size: 20px;
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }
    .user-box {
      &:hover {
        .hover-box {
          display: block;
        }
      }
    }
  }
  ul.bottom {
    border-top: 1px solid #2094c1;
  }
  .hover-box {
    position: absolute;
    top: 0px;
    left: 56px;
    background: #2094c1;
    min-height: 100vh;
    width: 240px;
    display: none;
    .heading {
      padding: 15px;
      h1 {
        font-family: $primary-font;
        font-size: 15px;
        color: #ffffff;
      }
    }
    ul {
      padding: 0px;
      margin: 0;
      li {
        a {
          font-family: $primary-font;
          font-size: 13px;
          padding: 15px 0px;
          font-weight: 500;
          width: 100%;
          display: block;
          transition: 0.2s;
          padding: 15px;
          color: #ffffff !important;
          &:hover {
            background: #1683ad;
            color: #6eaaee !important;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>