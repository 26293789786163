import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Tree from 'primevue/tree';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Paginator from 'primevue/paginator';
import Column from 'primevue/column';
import Password from 'primevue/password';
import 'primevue/resources/themes/mdc-light-indigo/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Amplify from 'aws-amplify';
import "@aws-amplify/ui-components";
import ColorPicker from 'primevue/colorpicker';
import Textarea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import ToggleButton from 'primevue/togglebutton';



// import '@aws-amplify/ui-vue';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';
import aws_exports from './aws-exports';



applyPolyfills().then(() => {
    defineCustomElements(window);
});

Amplify.configure(aws_exports);
const app = createApp(App);
app.use(PrimeVue);
app.use(store);
app.use(router).mount("#app");

app.directive('toggle', {
    bind(binding){
        binding = !binding
        return binding
    }
})

app.component('InputText', InputText);
app.component('Tree', Tree);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Message', Message);
app.component('Dropdown', Dropdown);
app.component('Button', Button);
app.component('InputSwitch', InputSwitch);
app.component('Calendar', Calendar);
app.component('Checkbox', Checkbox);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component("Password", Password)
app.component('ProgressSpinner', ProgressSpinner);
app.component('ColorPicker', ColorPicker);
app.component('Textarea', Textarea);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Dialog', Dialog);
app.component('ToggleButton', ToggleButton);
app.component('Paginator', Paginator);
