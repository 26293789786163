<template lang="">
    <div class="header">
     <div class="button-div">
        <Button class="p-button-info none-border" icon="fas fa-bars" iconPos="left" @click="onClickButton()" />
        <h1>{{$route.name}}</h1>
    </div>
       <Button label=" Log Out" icon="fas fa-power-off" iconPos="right"  @click='logout()' />
    </div>
     <div class="nav-overlay" v-bind:class="{active: toggelNav == true}" @click="onClickButton()"></div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return{
            toggelNav:true
        }
    },
    methods:{
        ...mapActions({
            globalLogoutVue            : "auth/globalLogout",

        }),
        logout : async function () {
            await this.globalLogoutVue();
            localStorage.clear();
            this.$router.push('/login');
        },
        onClickButton (event) {
            this.toggelNav = !this.toggelNav;
            this.$emit('collapse', this.toggelNav)
        }
    }
}
</script>
<style lang="scss" scope>

    @import 'header.scss';
    
</style>