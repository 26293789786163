import Loading from "../components/Loader";
export default[
    {
        path: "/login",
        name: "Login",
        component: () => import('../components/Auth/Login.vue')
    },
    {
        path: "/signup",
        name: "SignUp",
        component: () => import('../components/Auth/SignUp.vue'),
    },
    {
        path: "/confirmsignup/:username",
        name: "ConfirmSignup",
        component: () => import('../components/Auth/ConfirmSignup.vue'),
    },
    {
        path: "/forgetpassword",
        name: "ForgetPassword",
        component: () => import('../components/Auth/ForgetPassword.vue')
    },
    {
        path: "/loading/:token",
        name: "Loading",
        props: true,
        meta: { requiresAuth: false },
        component: Loading

    },
]