export default[
    {
        path: "/",
        name: "Home",
        meta: { requiresAuth: true, role: 'user' },
        component:  () => import('../views/Home/home.vue')

    },
    {
        path: "/project/:projectid",
        name: "Project",
        meta: { requiresAuth: true, role: 'user' },
        component: () => import('../views/Projects/projects.vue')

    },
    {
        path: "/all-projects",
        name: "AllProject",
        meta: { requiresAuth: true, role: 'user' },
        component:  () => import('../views/All-Project/all-project.vue')

    },
]