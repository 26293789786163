import * as Vue from "vue-router";
import aws_exports from '../aws-exports';
import service from '../service/auth-service'

import project from './project-route'
import admin from './admin-route'
import auth from './auth-route'



let allRoutes = []
const routes = allRoutes.concat(project, admin, auth)

const router = Vue.createRouter({
    history: Vue.createWebHistory(),
    routes: routes,
});
router.beforeEach(async(to, from, next) => {
    window.document.title = to.name ? to.name : 'Home';
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const routeRole = to.meta.role;
    let url = `https://cognito-idp.${aws_exports.aws_project_region}.amazonaws.com/`;
    var token = localStorage.getItem('userAccessToken');
    var isAuthenticated = false
    var isAdmin = false;
    if (token) {
        let body = { AccessToken: token };
        let responseAws = await service.apiExecute('post', url, body);
        isAuthenticated = (responseAws.status == 200) ? true : false;
        isAdmin = (responseAws.data.Username === 'admin') ? true : false;
    } else isAuthenticated = false

    //FOR USER PROTECTED SCREENS (PROJECTS, HOME)
    if (requiresAuth) {
        //IF ADMIN ROUTE
        if(routeRole === "admin") {
            if(isAuthenticated && isAdmin) next();
            else next('/loading/'+token);
        } else {
            if(isAuthenticated && !isAdmin) next();
            else next('/loading/'+token);
        }        
    } else if ((to.path == "/login" || to.path == '/signup') && isAuthenticated) {
        if(!isAdmin) next('/all-projects');
        else next('/admin');
    } else next();
})



export default router;