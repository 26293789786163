<template>
    <div class="main-container">
        <Sidenav  :nav='navbar'/>
        <div class="content" v-bind:class="{active: navbar == true}">
            <Header @collapse="toggelNav" />
            <div class="inner-content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Sidenav from '../Shared/Sidenav/sidenav'
import Header from '../Shared/Header/header'
    export default {
        name: "Public",
        data(){
            return{
                navbar : true,
            }
        },
        components: {
            Sidenav,
            Header
        },
        methods:{
            toggelNav (value) {
                this.navbar = value; 
            }
        }
    }
</script>

<style lang="scss" scoped>
@import 'public.scss';
</style>