<template>
  <div class="main-container d-flex">
    <div class="side">
      <Sidenave v-if="this.$route.meta.requiresAuth == true && this.$route.name != 'Home' && this.$route.meta.role != 'admin'"/>
    </div>
    <router-view />
  </div>
</template>

<script>
import Sidenave from "./components/Sidenave";
export default {
  name: "App",
  components: {
    Sidenave,
  },
  data(){
    return{
      url:null,
    }
  },
}
</script>
<style lang="scss">
.operationBtn{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 20px;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    button{
        padding: 10px;
        border-radius: 4px;
        background: #29ace0;
        border: none;
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
    }
}
</style>


