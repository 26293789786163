import axios from 'axios';
const client = axios.create({
    json: true
})
let apiExecute = (method, url, data) =>{
    return client({
        method,
        url: url,
        data,
        headers: {
            "Content-Type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser"
        }
    }).then(res => {
            return res;
    }).catch(err=> {
            return err.response;
    })
}
export  default {apiExecute};