/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:881c9b25-8dae-45d5-b4e9-d6960ade66f5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_eiEjLo6sf",
    "aws_user_pools_web_client_id": "22c5kkbtm89fc1kuphoflnk9gc",
    "oauth": {}
};


export default awsmobile;
