import { Auth } from "aws-amplify";
export const auth = {
    namespaced : true,
    state: { user: null },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions:{
        async logout({ commit }) {
            commit("setUser", null);
            return await Auth.signOut();
        },
        async globalLogout({ commit }) {
            commit("setUser", null);
            return await Auth.signOut({ global: true });
        },
        async login({ commit }, { username, password }) {
            try {
                await Auth.signIn({
                    username,
                    password
                });
                const userInfo = await Auth.currentUserInfo();
                commit("setUser", userInfo);
                return Promise.resolve();


            } catch (error) {
                return Promise.reject(error);
            }
        },
        
        async signUp(_, { username, password, email }) {
            try {
                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email,
                        // "custom:role" : 'admin'
                    }
                })
                return Promise.resolve();

            } catch (error) {
                return Promise.reject(error);

            }
        },
        async confirmSignUp(_, { username, code }) {
            try {
                await Auth.confirmSignUp(username, code);
                return Promise.resolve();

            } catch (error) {
                return Promise.reject(error);

            }
        },
        async forgetPassword(_,{ username }) {
            try {
                await Auth.forgotPassword(username);
                return Promise.resolve();
            } catch(error) {
                return Promise.reject(error);
            }
        },
        async forgetPasswordSubmit (_,{username, code, password }){
            try {
                await Auth.forgotPasswordSubmit(username, code, password);
                return Promise.resolve()
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async authAction({ commit }) {
            const userInfo = await Auth.currentUserInfo();
            commit("setUser", userInfo);

        },
        async resendCode(_,{username}){
            try {
                await Auth.resendSignUp(username);
                return Promise.resolve();
            }catch (error){
                return Promise.reject(error);
            }
        }
    },
    getters: {
        user: (state) => state.user
    }
}