import Public from '../views/Admin/Public/public.vue';
export default [
    {
        path: "/admin/",
        name: "Admin",
        meta: { requiresAuth: true, role: 'admin' },
        component: Public,
        children : [
            {
                path: 'add-component',
                name: 'CREATE COMPONENT',
                component: ()=>import(/* webpackchunksName : addcomponent */ '../views/Admin/Public/Add-components/add-components.vue'),
            },
            {
                path: 'components-list',
                name: 'VIEW COMPONENTS',
                component: ()=>import(/*  webpackchunksName : ComponentsList */ '../views/Admin/Public/Components-list/components-list.vue'),
            },
            {
                path: 'edit-component/:componentid',
                name: 'EDIT COMPONENT',
                component: ()=>import(/*  webpackchunksName : ComponentsList */ '../views/Admin/Public/Edit-Component/edit-component.vue'),
            },
        ],
    },
    {
        path: "/richtext",
        name: 'RTE',
        component: ()=> import('../components/RichTextEditor.vue')
    }
]